import * as React from "react";
import Header from "../../Components/Header/Header";
import Privacy from "../../Components/Privacy/Privacy";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";

const PrivacyPage = () => {
  return (
    <>
      <Seo locale="en-US" page="privacy" />
      <Header lang="en-US" />
      <HamburgerMenu />
      <Privacy lang="en-US" />
      <Footer lang="en-US" />
    </>
  );
};

export default PrivacyPage;
