import * as React from "react";
import { Container } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import "./Privacy.scss";
import query from "./query";
import PropTypes from "prop-types";

const Privacy = (props) => {
  let index = 0;
  if (props.lang === "it") {
    index = 1;
  }

  const privacyPolicy = query().allIubendaDocument.nodes[index].privacyPolicy;
  return (
    <Container className="privacy">{ReactHtmlParser(privacyPolicy)}</Container>
  );
};

Privacy.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Privacy;
