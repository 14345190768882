import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allIubendaDocument(sort: { fields: documentId }) {
        nodes {
          documentId
          privacyPolicy
        }
      }
    }
  `);
};

export default query;
